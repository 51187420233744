<template>
  <div class="page-view">
    <!-- <van-nav-bar title="治理措施" left-arrow safe-area-inset-top @click-left="onBack(-1)" /> -->
    <div class="content-view">
      <div class="white-bg-view" style="padding: 4px 0">
        <van-form ref="formRef" :show-error="false" disabled scroll-to-error input-align="left" validate-trigger="onSubmit">
          <van-field
            :value="attendanceTypeMap[form.leaveType]?.attendancename"
            label="请假类型"
            right-icon="arrow-down"
            placeholder="请选择"
            required
            :rules="[{ required: true, message: '请选择请假类型' }]"
          >
            <template v-if="form.leaveType === '6'" #extra>
              <span style="margin-left: 10px;font-size: 10px;color: #999999">可使用公休天数:{{ day }}天</span>
            </template>
          </van-field>
          <van-field
            v-model="form.startTime"
            label="开始时间"
            readonly
            required
            :rules="[{ required: true, message: '请选择开始时间' }]"
          >
            <template #input>
              <van-field
                v-model="form.startTime"
                placeholder="开始时间"
                readonly
                style="padding: 0"
              />
            </template>
            <template #button @click.stop="showDropdown">
              <van-dropdown-menu active-color="#1778FF" style="margin-right: 10px">
                <van-dropdown-item v-model="form.startPoint" :options="option1" disabled />
              </van-dropdown-menu>
            </template>
          </van-field>
          <van-field
            v-model="form.endTime"
            label="结束时间"
            readonly
            required
            :rules="[{ required: true, message: '请选择结束时间' }]"
          >
            <template #input>
              <van-field
                v-model="form.endTime"
                placeholder="结束时间"
                readonly
                disabled
                style="padding: 0"
              />
            </template>
            <template #button @click.stop="showDropdown">
              <van-dropdown-menu active-color="#1778FF" style="margin-right: 10px">
                <van-dropdown-item v-model="form.endPoint" :options="option1" disabled />
              </van-dropdown-menu>
            </template>
          </van-field>
          <van-field
            v-model="form.leaveDay"
            label="请假时长"
            readonly
          >
            <template #extra>
              <span>天</span>
            </template>
          </van-field>
          <van-field
            v-model="form.extFiled1"
            label="请假事由"
            placeholder="请输入请假事由"
            type="textarea"
            rows="5"
            maxlength="200"
            show-word-limit
            readonly
            required
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field
            v-model="form.leaveDate"
            label="申请时间"
            readonly
            placeholder="请选择"
          />
          <van-field name="uploader" label="佐证材料">
            <template #input>
              <div style="display: flex;flex-direction: column">
                <van-uploader
                  v-model="fileList"
                  preview-size="64"
                  :show-upload="false"
                  readonly
                  :deletable="false"
                />
              </div>
            </template>
          </van-field>
        </van-form>
      </div>
      <div class="white-bg-view" style="padding:16px 12px;margin-top: 10px">
        <van-form ref="popupFormRef" :show-error="false" input-align="left" validate-trigger="onSubmit">
          <van-field
            :value="optionMap[auditForm.rejected]"
            label="审批意见"
            required
            right-icon="arrow-down"
            :rules="[{ required: true, message: '请选择审批意见' }]"
            @click.stop="showTypePicker=true"
          />
          <van-field
            v-model="auditForm.approvalMsg"
            rows="5"
            type="textarea"
            label="备注"
            maxlength="200"
            placeholder="添加备注"
            required
            :rules="[{ required: true, message: '请添加备注' }, { min: 1, max: 200, message: '长度在 3 到 200 个字符' }]"
          />
          <van-field
            v-if="!((form.leaveDay <= 1 && form.extFiled4 != null && form.extFiled3 === '主任审核')||(form.extFiled4 != null && form.extFiled3 === '人资主任审核'))&&!auditForm.rejected"
            v-model="auditForm.approvalPersonName"
            label="审批人"
            right-icon="arrow-down"
            placeholder="请选择"
            required
            :rules="[{ required: true, message: '请选择下一节点审批人' }]"
            @click.stop="selectApprovalPerson"
          />
        </van-form>
      </div>
      <div v-if="workFLowTableData&&workFLowTableData.length" class="white-bg-view" style="padding:16px 12px;margin-top: 10px">
        <div v-for="(t,i) in workFLowTableData" :key="t.id" class="timeline-item">
          <div class="timeline-box">
            <img v-if="!i" src="@/assets/personal/icon_header0.png" style="width:30px;height:30px;" alt="icon">
            <img v-else src="@/assets/personal/icon_header1.png" style="width:30px;height:30px;" alt="icon">
            <div v-if="i!==workFLowTableData.length-1" class="long-line" />
          </div>
          <div class="timeline-content">
            <div class="flex-row" style="align-items:center;justify-content:space-between">
              <span style="font-size:15px;color:#03081A;font-weight:550;" class="flex-row flex-1">{{ t.nodeName }}</span>
              <span style="font-size:15px;font-weight:550;" class="flex-row" :class="t.shresult==='同意'?'main-color':t.shresult==='驳回'?'red-color':'default-color'">{{ t.shresult||'' }}</span>
            </div>
            <div class="flex-row" style="align-items:center;justify-content:space-between;margin-top: 10px">
              <span style="font-size:15px;color:#03081A;" class="flex-row flex-1">{{ t.optUserName }}</span>
              <span style="font-size:14px;color:#03081A;">{{ t.endTime ? t.endTime : '' }}</span>
            </div>
            <span v-if="t.shmsg" style="align-items:center;margin-top: 10px;font-size: 14px">{{ t.shmsg||'' }}</span>
          </div>
        </div>
      </div>
      <div class="bottom-btn-view">
        <van-button size="small" type="default" round block @click.stop="onBack(-1)">取消</van-button>
        <van-button size="small" type="primary" style="background: #1778FF;border: none" round block @click.stop="handleAudit()">提交</van-button>
      </div>
    </div>
    <van-popup v-model="showApprovalPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="approvalPersons"
        value-key="name"
        @confirm="onApprovalConfirm"
        @cancel="showApprovalPicker = false"
      />
    </van-popup>
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="options"
        value-key="label"
        @confirm="onTypeConfirm"
        @cancel="showTypePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { exportRequest, postRequest } from '@/utils/api'
import { blobType } from '@/utils/blobType'

export default {
  name: 'AddFrom',
  data() {
    return {
      id: '',
      detail: { },
      form: {
        id: '',
        remark: '', //
        leaveType: '',
        resource: '',
        leaveDay: 0,
        extFiled1: '', // 请假事由
        leaveDate: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
        startTime: '', // 开始日期
        endTime: '', // 结束日期
        startPoint: 'AM', // 开始时刻
        endPoint: 'PM', // 结束时刻
        yearMonth: new Date().getFullYear(),
        delFlag: '1',
        approvalPerson: '',
        extFiled2: 'APP', // PC端请假
        extFiled3: '暂存',
        extFiled4: '', // InstanceID
        extFiled5: '', // taskID
        isSend: 1
      },
      fileList: [],
      currentDate: new Date(),
      attendanceType: [],
      attendanceTypeMap: {
        '5': {
          attendancename: '事假'
        }
      },
      value1: 0,
      option1: [
        { text: '上午', value: 'AM' },
        { text: '下午', value: 'PM' }
      ],
      approvalPersons: [],
      showApprovalPicker: false,
      day: 0,
      auditForm: {
        approvalMsg: '同意',
        approvalPerson: '',
        approvalPersonName: '',
        rejected: 0
      },
      showTypePicker: false,
      optionMap: {
        1: '驳回',
        0: '通过'
      },
      options: [{ label: '通过', value: 0 }, { label: '驳回', value: 1 }],
      workFLowTableData: []
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
    userName() {
      return this.$store.getters.userName
    }
  },
  created() {
    this.getAttendanceType()
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
      this.getFileInfo()
    }
  },
  mounted() {
  },
  methods: {
    // 根据计划ID查询基础信息和隐患列表
    getDetail() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      const param = new FormData()
      param.append('id', this.id)
      postRequest('/pems/attendance/tAttendanceLeaveDate/findTAttendanceLeaveDateById', param).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          return
        }
        this.form = res.data?.resultValue
        this.getProcess()
        this.getApprovalPersons()
        this.getUserGxLeaveDate()
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 获取请假类型
    getAttendanceType() {
      postRequest('/pems/attendance/tAttendanceData/findAttendanceType', {}).then(res => {
        if (!res.data.successful) {
          return
        }
        this.attendanceType = res.data.resultValue || []
        const typeMap = {}
        for (const item of this.attendanceType) {
          typeMap[item.id] = item
        }
        this.attendanceTypeMap = typeMap
      })
    },
    // 获取审批人
    getApprovalPersons() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      if (this.form.leaveDay > 1 && this.form.extFiled4 != null && this.form.extFiled3 === '主任审核') {
        postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersonsByParam', {
          types: 'RZZY',
          deptid: '68000004'
        }).then(res => {
          this.$toast.clear()
          if (!res.data.successful) {
            return
          }
          this.approvalPersons = res.data.resultValue || []
          if (this.approvalPersons && this.approvalPersons.length) {
            this.auditForm.approvalPerson = this.approvalPersons[0].id
            this.auditForm.approvalPersonName = this.approvalPersons[0].name
          }
        }).catch(() => {
          this.$toast.clear()
        })
        return
      }
      postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersons', {}).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          return
        }
        this.approvalPersons = res.data.resultValue || []
        if (this.approvalPersons && this.approvalPersons.length) {
          this.auditForm.approvalPerson = this.approvalPersons[0].id
          this.auditForm.approvalPersonName = this.approvalPersons[0].name
        }
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 获取公休剩余天数
    getUserGxLeaveDate() {
      postRequest('/pems/attendance/tAttendanceLeaveDate/getUserGxLeaveDate', {
        userId: this.form.userId,
        id: this.form.id || null
      }).then(res => {
        if (!res.data.successful) {
          return
        }
        this.day = res.data.resultValue || 0
      })
    },
    // 获取审批流程
    getProcess() {
      const form = new FormData()
      form.append('processInstanceId', this.form.extFiled4)
      postRequest('/pems/attendance/tAttendanceLeaveDate/getHistoryTaskList', form).then(res => {
        if (!res.data.successful) {
          return
        }
        this.workFLowTableData = res.data.resultValue || []
      })
    },
    onBack(index) {
      this.$router.go(index)
    },
    onApprovalConfirm(val) {
      console.log('confirm===', val)
      this.auditForm.approvalPerson = val.id
      this.auditForm.approvalPersonName = val.name
      this.showApprovalPicker = false
    },
    selectApprovalPerson() {
      this.showApprovalPicker = true
    },
    showDropdown() {},
    // 获取附件信息
    getFileInfo() {
      postRequest('/pems/system/file/getFileInfo', {
        id: null,
        groupid: null,
        businessid: this.id
      }).then(res => {
        if (!res.data.successful) {
          return
        }
        const fileList = res.data.resultValue || []
        for (const item of fileList) {
          item.status = 'success'
          item.message = '上传成功'
          item.content = ''
          item.url = null
        }
        this.fileList = fileList
        this.getFiles(fileList)
      }).catch(() => {
      })
    },
    // 审核拒绝
    handleAudit() {
      this.$refs['popupFormRef'].validate().then(() => {
        this.$dialog.confirm({
          title: '提示',
          message: '是否对该请假进行审批',
          confirmButtonColor: '#1778FF',
          confirmButtonText: '是',
          cancelButtonText: '否'
        }).then(() => {
          if (this.auditForm.rejected === 1 ||
           (!((this.form.leaveDay <= 1 && this.form.extFiled4 != null && this.form.extFiled3 === '主任审核') ||
           (this.form.extFiled4 != null && this.form.extFiled3 === '人资主任审核')) && this.auditForm.rejected === 0)) {
            this.$dialog.confirm({
              title: '提示',
              message: '是否发送短信给审批人',
              confirmButtonColor: '#1778FF',
              confirmButtonText: '是',
              cancelButtonText: '否'
            }).then(() => {
              this.form.isSend = 1
            }).catch(() => {
              this.form.isSend = 0
            }).finally(() => {
              this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 30000
              })
              postRequest('/pems/attendance/tAttendanceLeaveDate/approvalPersonalLeaveData', {
                ...this.form,
                approvalPerson: this.auditForm.approvalPerson,
                approvalOption: this.auditForm.approvalMsg,
                rejected: this.auditForm.rejected,
                isSend: this.form.isSend ? 1 : 0
              }).then(res => {
                this.$toast.clear()
                if (!res.data.successful) {
                  return
                }
                this.$toast.success('审核成功')
                this.onBack(-1)
              }).catch(() => {
                this.$toast.clear()
                this.$toast('审核失败')
              })
            })
          } else {
            this.$toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration: 30000
            })
            postRequest('/pems/attendance/tAttendanceLeaveDate/approvalPersonalLeaveData', {
              ...this.form,
              approvalPerson: this.auditForm.approvalPerson,
              approvalOption: this.auditForm.approvalMsg,
              rejected: this.auditForm.rejected,
              isSend: 0
            }).then(res => {
              this.$toast.clear()
              if (!res.data.successful) {
                return
              }
              this.$toast.success('审核成功')
              this.onBack(-1)
            }).catch(() => {
              this.$toast.clear()
              this.$toast('审核失败')
            })
          }
        }).catch(() => {
          this.$toast('取消审核')
        })
      }).catch(e => {
        this.$toast.clear()
        console.log(e)
      })
    },
    onTypeConfirm(val) {
      console.log('confirm===', val)
      this.auditForm.rejected = val.value
      if (val.value) {
        this.auditForm.approvalMsg = '不同意'
      } else {
        this.auditForm.approvalMsg = '同意'
      }
      this.showTypePicker = false
    },
    getFiles(fileList) {
      for (const item of fileList) {
        this.downloadFile(item.name, item.path, item)
      }
    },
    // 上传文件
    downloadFile(name, path, file) {
      exportRequest('/pems/system/file/downloadFile', {
        fileName: name,
        filePath: path
      }).then(res => {
        let fileType = 'png'
        if (name) {
          fileType = name.split('.')[1]
        }
        const blob = new Blob([res.data], {
          type: blobType[fileType]
        })
        console.log('blob===', blob)
        this.blobToBase64(blob).then(base64 => {
          file.content = base64
          this.$set(file, 'file', blob)
          console.log('file===', file)
          console.log('fileList===', this.fileList)
        })
      })
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = (e) => {
          reject(e)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-view {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .content-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;
    background-color: #f5f6f9;

    .option-view{
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #03081A;
      font-weight: 550;
      padding: 10px 12px;
    }

    .white-bg-view{
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 8px 0;

      .tip-txt{
        margin: 8px 0;
        font-size: 12px;
        color: #999999;
        padding-left: 14px;
      }

      .cell-view{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 10px 12px;

        .label-txt{
          color: #838DA0;
          margin-right: 40px;
          width: 88px;
        }

        .value-txt{
          color: #03081A;
          display: flex;
          flex: 1;
          text-align: right;
          justify-content: flex-end;
        }
      }

      .file-list-view{
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-item-view{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #1778FF;
          padding: 2px 0;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
        }
      }

      .gray-bg-view{
        display: flex;
        flex-direction: column;
        background: #F6F6F6;
        border-radius: 8px;
        padding: 12px 15px;
        flex: 1;

        .value-txt{
          font-size: 16px;
          color: #03081A;
          margin-top: 10px;
          margin-left: 20px;
        }
      }
    }

    .button-view{
      display: flex;
      column-gap: 20px;
      margin-top: 20px;
      padding: 0 12px;
      margin-bottom: 50px;

      .add-btn{
        flex: 1;
        background-color: white;
        border-radius: 8px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .add-btn-primary{
        display: flex;
        flex: 1;
        background: #1778FF;
        border-radius: 8px;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
      }

    }

    .bottom-btn-view{
      display: flex;
      justify-content: space-between;
      padding: 15px 20px 0 20px;
      column-gap: 10px;
      position: fixed;
      bottom: 10px;
      left: 0;
      right: 0;
    }
  }
}
 /* 时间线 */
.timeline-item {
  position: relative;
  display: flex;
  height: auto;
  width: 100%;

  .timeline-box {
    text-align: center;
    position: absolute;
    height: 100%;

    .long-line {
      width: 1px;
      height:calc(100% - 50px);
      border-left: 1px dashed #1778FF;
      margin-left: 15px;
    }
  }

  .timeline-content {
    box-sizing: border-box;
    margin-left: 20px;
    min-height: 60px;
    padding: 5px 0 0 20px;
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .main-color{
      color: #1778FF;
    }
    .red-color{
      color: red;
    }
    .default-color{
      color:#03081A;
    }
  }

  .user-view {
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #03081A;
    padding: 2px 12px 2px 2px;
    background: #F2F3F5;
    border-radius: 14px;
    align-self: baseline;
    margin-top: 5px;
  }

}

.timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
}

::v-deep .van-dropdown-menu__bar{
  height: 100%;
  -webkit-box-shadow: none;
  box-shadow:none;
}
::v-deep .van-dropdown-menu__title{
  padding: 0 20px;
}
::v-deep .van-dropdown-menu__title::after{
  border-color: transparent transparent #333333 #333333;
}
</style>
